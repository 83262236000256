/********************
  WebPage styling
********************* @import "node_modules/react-image-gallery/styles/css/image-gallery.css"; / 

/** General style **/
@media only screen and (min-width : 1200px) {

  .container { 
    width: 1400px; 
    max-width: 1400px;
  } 
}
body {
  font-family: "crimson",serif;
  color: #4a4a4a;
  font-weight: 400;
}
.hero-spacing {
  margin-bottom: 10rem;
}
h1 {
  font-family: "crimson",serif;
  display: inline-block;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  letter-spacing: .125rem;
  line-height: 1.5;
  text-align: center;
}
h1:after {
  content: '';
  display: block;
  width: 3.125rem;
  height: .0625rem;
  margin: 1.875rem auto;
  background-color: #9d9272;
}
h3 {
  font-family: "roboto",sans-serif;
  font-weight: 700;
  color: #000;
  letter-spacing: .125rem;
  font-size: .95rem;
}
.bold {
  font-weight: 600;
}
.text-small {
  font-size: .85rem;
}
.remove-margin-bottom {
  margin-bottom: 0;
}
.icon-vertical-divider {
  display: block;
  position: relative;
  height: 6.875rem;
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.icon-vertical-divider::before {
  display: block;
  content: '';
  width: 1px;
  background-color: #9d9272;
  height: 6.875rem;
  position: absolute;
  left: 50%;
}
/** Header **/
.navbar {
  padding-top: 2%;
  padding-bottom: 2%;  
}
.nav-item {
  text-align: center;
  cursor: pointer;
}
.nav-item a {
  font-family: "roboto",sans-serif;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  letter-spacing: .125rem;
  font-size: .75rem;
}
/** Top banner **/
.banner-image {
  width: 100%;
}
.banner-text {
  position: absolute;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  font-style: italic;
  font-family: crimson;
  top: 45%;
  left: 5%;
}
.banner-text span {
  display: block;
  text-align: right;
}
.banner-text img {
  position: relative;
  top: -15px;
}
.banner-call {
  padding-top: 1.625em;
  font-size: .775rem;
  color: #968f8b;
  line-height: 1.3;
  font-weight: 600; 
  text-align: right;
}
.banner-call a {
  color: #968f8b;
}
.banner-call a:hover {
  color: #968f8b;
  text-decoration: none;
}
/** About section **/
.about h3 {
  min-height: 36px;
}
.about [class*="col-"] {
  text-align: center;
}
.about svg.svg-inline--fa {
  font-size: 34px;
  color: #9d9272;
  margin: 15px 0;
}
/** Services section **/
.services img {
  width: 100%;
}
.services ul {
  list-style-type: none;
}
.services ul li {
  padding: 5px 0;
}
.services svg.svg-inline--fa {
  font-size: 14px;
  color: #9d9272;
  margin-right: 10px;
}
.services .note {
  padding-left: 40px;
  position: relative;
  top: -5px;
}
.services svg.svg-inline--fa {
  font-size: 10px;
  position: relative;
  top: -5px;
  margin-right: 5px;
}
/** Photo Gallery **/
.photo-gallery {
  display: inline-block;
}
/** Contact **/
.contact svg {
  color: #7d7773;
  margin-right: 5px;
}
.contact div {
  padding-top: 5px;
  padding-bottom: 5px;
}
.contact [class*='col-'] span {
  font-weight: 600;
}
.contact [class*='col-'] span:last-child{
  font-style: italic;
  font-weight: 400;
}
/** Footer **/
.footer{
  position: absolute;
  bottom: 0;
  background-color: #4a4a4a;
  opacity: 0.8;
}
.footer p {
  font-size: .75rem;
  text-align: center;
  width: 100%;
  color: #9d9272;
  font-weight: bold;
}
.footer ul {
  list-style-type: none;
  padding-left: 0;
  width: 40%;
  margin: 1rem auto;
  padding-top: 15px;
  padding-bottom: 15px;
}
.footer ul li a:not([href]):not([tabindex]):hover {
  color: #cAcAc8;
  transition: color .15s;
}
.footer li {
  display: inline-block;
}
.f-contact{
  width: 100% !important;
  margin: 0 !important;
}
.f-contact li{
  padding: 0 0.9rem;
}
.footer a.icon-text:hover {
  color: #cAcAc8;
  transition: color .15s;
}
.footer a.icon-text {
  color: #9d9272;
  text-decoration: none;
  transition: color .15s;
  display: table;
  margin: auto;
  margin-top: 1rem;
}
.footer a span {
  font-weight: bold;
  padding-left: 5px;
  display: table-cell;
  vertical-align: middle;
  font-style: italic;
}
.footer .svg-inline--fa.fa-w-14 {
  width: 1.875em;
  height: 1.5em;
}
.content-info{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);  
}
.content-info ul{
  -webkit-padding-start: 0px !important;
          padding-inline-start: 0px !important;
}
.heroContainer{
  padding: 0 7%;
}
.daisies{
  margin: 0 !important;
  font-size: 20px !important;
  color: #ff8c00 !important;
}
.btn-primary{
  display: inline-block;
  font-size: .6875rem;
  font-family: "roboto",sans-serif;
  font-weight: 700;
  color: #9d9272;
  text-transform: uppercase;
  letter-spacing: .0625rem;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  transition: all .15s ease-out;
  padding: .6875rem 1rem .625rem;
  margin-bottom: 1.25rem;
  border: 1px solid #9d9272;
  border-radius: 1.6875rem;
  background-color: #fff;
  margin-right: 1rem;
}
.btn-primary:hover{
  color: #fff;
  background-color: #9d9272;
  border: 1px solid #9d9272;
}
.gmap{
  height: 500px;
  min-height: 500px;
  padding: 0;
  border-bottom: 1px solid #9d9272;
}
.gmap-items svg{
  font-size: 20px;
  padding-top: 5px;
}
.gmap-items.main-icon{
  border: '3px solid #BFB427' !important;
  width: 55px !important;
  height: 55px !important;
  padding: 5px !important;
  
}
.gmap-items.main-icon svg{
  font-size: 30px;
}
